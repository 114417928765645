export const formatArticleSection = (projects, locale) =>
  projects.edges.map(({ node }) => {
    const content = new Date(node.frontmatter.date).toLocaleString(locale, {
      month: "long",
      year: "numeric",
    })
    return {
      path: node.fields.slug,
      title: node.frontmatter.title,
      content,
      footer: node.frontmatter.categories.join(" "),
      thumbnail: node.frontmatter.thumbnail,
    }
  })
