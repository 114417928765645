import React from 'react';
import { navigate, graphql } from 'gatsby';
import { Section, SectionTitle } from 'react-komponents';

import { PageLayout } from '../components/Layouts/layout';
import { ImagesGallery } from '../components/ImagesGallery/imagesGallery.component';
import { formatArticleSection } from '../utils/formatArticleSection';

const BlogSection = ({ location, data, pageContext }) => {
  const section = formatArticleSection(data.articles, 'fr-FR');
  return (
    <PageLayout location={location}>
      <Section beta>
        <SectionTitle>{pageContext.title}</SectionTitle>
        <ImagesGallery onLinkClick={navigate} cards={section} />
      </Section>
    </PageLayout>
  );
};

export const pageQuery = graphql`
  query allArticles($type: String!) {
    articles: allMarkdownRemark(
      limit: 1000
      sort: { fields: [frontmatter___date], order: DESC }
      filter: { frontmatter: { template: { eq: $type } } }
    ) {
      edges {
        node {
          fields {
            slug
          }
          excerpt
          timeToRead
          frontmatter {
            title
            categories
            thumbnail {
              childImageSharp {
                gatsbyImageData(formats: WEBP, width: 500, layout: CONSTRAINED)
              }
            }
            date
            template
          }
        }
      }
    }
  }
`;

export default BlogSection;
