import React, { useCallback } from 'react';
import styled from 'styled-components';
import { GatsbyImage } from 'gatsby-plugin-image';
import { CardImage, MEDIA_TABLET } from 'react-komponents';
import ArrayUtils from '../../utils/array';

const DEFAULT_IMAGES_PER_SECTION = 3;

const Card = ({ thumbnail, title, content, footer, path, onLinkClick }) => {
  const onClickCallback = useCallback(() => onLinkClick(`/${path}`), [
    path,
    onLinkClick,
  ]);

  return (
    <CardContainer onClick={onClickCallback}>
      <CardImage
        title={title}
        content={content}
        footer={footer}
        path={`/${path}`}
        renderImage={() => (
          <GatsbyImage
            image={thumbnail.childImageSharp.gatsbyImageData}
            style={{ height: '100%', width: '100%' }}
            objectFit="cover"
          />
        )}
      />
    </CardContainer>
  );
};

export const ImagesGallery = ({ cards: allCards, onLinkClick }) => {
  const cardsSections = ArrayUtils.chunk(allCards, DEFAULT_IMAGES_PER_SECTION);
  return (
    <Container>
      {cardsSections.map(cards => (
        <ListPartContainer>
          {cards.map(props => (
            <Card {...props} onLinkClick={onLinkClick} />
          ))}
        </ListPartContainer>
      ))}
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
`;

const ListPartContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: row;
  @media (max-width: ${MEDIA_TABLET}px) {
    flex-direction: column;
  }
`;

const CardContainer = styled.div`
  display: flex;
  flex: ${1 / DEFAULT_IMAGES_PER_SECTION};
  flex-wrap: wrap;
  height: 300px;
`;
